<template>
  <manage type="hywj"></manage>
</template>

<script>
import Manage from './manage'

export default {
  components: {
    Manage
  }
}
</script>
